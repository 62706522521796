const InformationModule = {
  state: () => ({
    informations: [],
    allowedMediaTypes: [],
    counter: 0,
    informationEntry: {},
    informationEntryPortal: {},
    searchResultsCounter: 0,
    searchAttempt: 0,
    isSearching: false,
  }),
  mutations: {
    setItems(state, items) {
      state.informations = items;

      if (state.searchAttempt === 0 && state.isSearching) {
        state.searchResultsCounter = state.informations.count;
        state.searchAttempt++;
      }
    },

    setinformationEntry(state, item) {
      state.informationEntry = item;
    },

    setinformationEntryPortal(state, item) {
      state.informationEntryPortal = item;
    },

    setInformationEntriesCount(state, counter) {
      state.counter = counter;
    },

    allowedMediaTypes(state, items) {
      state.allowedMediaTypes = items;
    },

    save(state, item) {

      state.informations.portalDtos.push(Object.assign({}, item));

      /* This line sorts the `portalDtos` array based on the `title` property of each object within the array. 
 The `localeCompare` method is used for the comparison, which compares the titles according to the rules of the German language (indicated by the "de" locale).
  The option `{ sensitivity: "base" }` is passed to `localeCompare` to ensure that the comparison is case-insensitive and diacritic-insensitive. 
 This means that differences in case (uppercase vs. lowercase) and differences in diacritic marks (like umlauts in German) are ignored during the comparison.
 As a result, the array `portalDtos` is sorted alphabetically by title, ignoring case and diacritic variations, according to the sort order defined by the German language.

*/
      state.informations.portalDtos.sort((a, b) =>
        a.title.localeCompare(b.title, "de", { sensitivity: "base" })
      );
      state.counter++;
    },

    delete(state, item) {
      const index = state.informations.portalDtos.findIndex(
        (information) => Number(information.id) === Number(item.id)
      );

      state.informations.portalDtos.splice(index, 1);
    },

    update(state, item) {
      const existsAtIndex = state.informations.portalDtos.findIndex(
        (information) => Number(information.id) === Number(item.id)
      );
      // Replace the object in array
      state.informations.portalDtos[existsAtIndex] = item;
      // Clone the array to trigger a UI update
      state.informations = [...state.informations];
    },

    resetSearch(state) {
      state.searchResultsCounter = 0;
      state.searchAttempt = 0;
      state.isSearching = false;
    },

    isSearching(state, item) {
      state.isSearching = item;
    },

    setSearchAttempt(state, item) {
      state.searchAttempt = item;
    },
  },
  getters: {
    informations: (state) => {
      return state.informations.portalDtos;
    },

    searchResultsCounter: (state) => {
      return state.searchResultsCounter;
    },

    getInformationEntryPortal: (state) => {
      return state.informationEntryPortal;
    },

    informationEntry: (state) => (title) => {
      let foundInformation;
      state.informations.filter((element) => {
        if (element.informationEntryDocumentsDtoList) {
          foundInformation = element.informationEntryDocumentsDtoList.find(
            (information) =>
              information.title === title || information.titleEN === title
          );
        } else {
          foundInformation = element.informationEntryContactDtos.find(
            (information) =>
              information.title === title || information.titleEN === title
          );
        }
      });
      return foundInformation;
    },

    getInformation: (state) => (title) => {
      return state.informations.find(
        (information) =>
          information.title === title || information.titleEN === title
      );
    },

    information: (state) => (id) => {
      return state.informations.portalDtos.find(
        (information) => Number(information.id) === Number(id)
      );
    },

    allowedMediaTypes: (state) => {
      return state.allowedMediaTypes.join(", ");
    },

    informationEntriesCount: (state) => {
      return state.counter;
    },

    informationEntryDocumentsDtoList: (state) => (index) => {
      return state.informations.portalDtos[index].informationEntryContactDtos;
    },

    informationsByCluster: (state) => (cluster) => {
      state.informations.filter((information) => {
        if (information.clusterDto.id === cluster.id) {
          return information;
        }
      });
    },
  },
  actions: {
    setItems({ commit }, items) {
      commit("setItems", items);
    },
    save({ commit }, item) {
      commit("save", item);
    },
    delete({ commit }, item) {
      commit("delete", item);
    },
    update({ commit }, item) {
      commit("update", item);
    },
    allowedMediaTypes({ commit }, items) {
      commit("allowedMediaTypes", items);
    },
    setInformationEntriesCount({ commit }, counter) {
      commit("setInformationEntriesCount", counter);
    },
    setinformationEntry({ commit }, item) {
      commit("setinformationEntry", item);
    },

    setinformationEntryPortal({ commit }, item) {
      commit("setinformationEntryPortal", item);
    },
  },
};

export default InformationModule;
