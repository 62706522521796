const LoggedInCompanyModule = {
  state: {
    loggedInCompany: JSON.parse(sessionStorage.getItem('logged-in-company')) || null
  },
  mutations: {
    setLoggedInCompany(state, payload) {
      state.loggedInCompany = payload;
      sessionStorage.setItem('logged-in-company', JSON.stringify(payload));
    }
  },
  actions: {
    setLoggedInCompany({ commit }, data) {
      commit('setLoggedInCompany', data);
    },
    initializeLoggedInCompany({ commit }) {
      const companyData = JSON.parse(sessionStorage.getItem('logged-in-company'));
      if (companyData) {
        commit('setLoggedInCompany', companyData);
      }
    }
  },
  getters: {
    getLoggedInCompany: (state) => state.loggedInCompany,
    isCompanyLoggedIn: (state) => !!state.loggedInCompany
  }
};

export default LoggedInCompanyModule;