<template>
  <div 
    class="profile-button" 
    @click="showProfile = !showProfile" 
    @keydown.enter="showProfile = !showProfile">
    {{ letters }}
  </div>

  <div 
    v-show="showProfile" 
    class="company-wrapper overlay">
    <div class="company-grid">
      <div class="company-icon">
        {{ letters }}
      </div>
      <div>
        <b>
          {{ company.companyName }}
        </b>
        <div>
          {{ company.email }}
        </div>
      </div>

      <div>
        {{ company.address.street }} 
        {{ company.address.houseNumber }}
      </div>

      <div>
        {{ company.address.postalCode }} 
        {{ company.address.location }}
      </div>

      <Button 
        label="Abmelden" 
        severity="secondary"
        icon="fa,arrow-right-from-bracket" 
        outlined
        @click="logout" 
        @keydown.enter="logout">
        <template #icon>
          <font-awesome-icon
            :icon="['fa', 'arrow-right-from-bracket']"
            class="logout-icon"
          />
        </template>
      </Button>
    </div>

  </div>

</template>

<script setup>
import { ref, watch, computed } from "vue";
import Button from "primevue/button";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const showProfile = ref(false)
const letters = ref('')

const company = computed(() => store.getters['loggedInCompany/getLoggedInCompany'])

watch(company, (newCompany) => {
  if (newCompany && newCompany.companyName) {
    letters.value = `${newCompany.companyName.slice(0, 1).toUpperCase()}`;
  }
}, { immediate: true });

const logout = () => {
  showProfile.value = false;
  sessionStorage.removeItem('logged-in-company');
  router.push({ name: 'DataExchange' });
}
</script>

<style lang="scss">
@mixin circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e50056;
  background-color: #fff;
  border: 1px solid #e50056;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 35px;
  font-weight: bold;
}

.company-grid {
  display: grid; 
  grid-template-columns: 35px 1fr;
  grid-template-rows: auto auto auto auto;
  grid-gap:5px 10px;
  grid-template-areas: 
    "company-icon  ."
    "company-icon  ."
    "company-icon  ."
    "company-icon  ."; 
  b {
    font-size: 1rem;
    display: block;
    width: 100%;
    padding-bottom: 5px;
    & + div {
      padding-bottom: 10px;
      width:100%;
    }
  }
  .company-icon {
    grid-area: company-icon;
    @include circle;
  }
  button {
    margin-top: 20px;
  }
}

.profile-button {
  @include circle;
  margin-left: auto;
  margin-top: 10px;
  cursor: pointer;
}

.company-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 70px auto;
  width: 20%;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  gap: 15px;
}

.overlay {
  position: absolute;
  width: 18rem;
  top: 0;
  right: 0;
  background: white;
  z-index: 99999;
  -webkit-box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);
  box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);
}

.overlay:after {
  content: "";
  position: absolute;
  top: -15px;
  right: 6px;
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}
</style>