import store  from '../store-modules';
import router from '../router'
import {apiUnternehmensPortal, apiInformationsPortal, apiDataExchange} from './api'
import axios from 'axios'
import { managementApiParams } from "../utils/helpers/managementApiParams";

axios.get('/config.json').then((res)=>{
    apiInformationsPortal.defaults.baseURL = res.data['VUE_APP_INFORMATIONSPORTAL'];
    apiUnternehmensPortal.defaults.baseURL = res.data['VUE_APP_UNTERNEHMENSPORTAL'];
    apiDataExchange.defaults.baseURL = res.data['VUE_APP_DATAEXCHANGE'];
})
export default class CrudApi {

    constructor(apiName, module) {

        this.apiName = apiName;
        this.module = module;
        this.apiUnternehmensPortal = apiUnternehmensPortal;
        this.apiInformationsPortal = apiInformationsPortal;
        this.apiDataExchange = apiDataExchange;
        this.store = store;
    }

   async getAll({operationParams = managementApiParams}) {
    
        await this.apiUnternehmensPortal.get( `${this.apiName}/getAll?locale=${operationParams.locale}&page=${operationParams.page}&size=${operationParams.rows}&sortOrder=${operationParams.sortOrder}`).then((response) => {
            this.store.dispatch(`${this.module}/setItems`, response.data)
        }).catch((error) => {
            this.errorHandling(this.module, error.response);
        })
    }

    async getById(id) {
        await this.apiUnternehmensPortal.get(this.apiName + id).then((response) => {
            return response.data;
        }).catch((error) => {
            this.errorHandling(this.module, error.response);
        })
    }

    async save(payload){
        await this.apiUnternehmensPortal.post(this.apiName +'/save', payload,  this.store.state.keycloak.jwt).then(response=>{
            this.store.dispatch(`${this.module}/save`, response.data)
        }).catch((error)=>{
            this.errorHandling(this.module, error.response);
        })
    }

    async update(payload) { 
        await this.apiUnternehmensPortal.put(this.apiName + '/update', payload, this.store.state.keycloak.jwt).then((response)=>{
            this.store.dispatch(`${this.module}/update`, response.data)
            this.store.dispatch('userFeedback/showUserFeedback', {
                color: "success",
                summary: 'Aktualisiert',
                message: `Die Aktualisierung wurde erfolgreich übernommen`,
                timeout: 3000
            })
        }).catch((error)=>{
            this.errorHandling(this.module, error.response);
        })
    }

    
    async delete(payload) {
        await this.apiUnternehmensPortal.delete(this.apiName +'/delete', {data: payload}, this.store.state.keycloak.jwt).then((response)=>{
            this.store.dispatch(`${this.module}/delete`, response.data)
        }).catch((error)=>{
            this.errorHandling( this.module,error.response);
        })
     }
     

    errorHandling(module, error){

        switch (error?.status) {
            case 400: { 
                console.error("Bad Request!" ,module, error)
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 400: Bad Request + ${error.data}`,
                    timeout: 3000
                })
                break;
            }
            case 401: {
                // Handle Unauthorized calls here
                // Display an alert
                console.error("Not authorized!", error)
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 401: Nicht autorisiert`,
                    timeout: 3000
                })
                break;
            }  
            case 403: {
                // Handle Unauthorized calls here
                // Display an alert
                console.error("Forbidden - Not authorized! You don’t have permission to access this server", error)
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 403: Nicht autorisiert`,
                    timeout: 3000
                })
                break;
            }    
            case 404: {
                // Not found
                // Display an alert
                console.error("Not found!", error)
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 404: Nicht gefunden`,
                    timeout: 3000
                })
                router.push({
                    path: "Fehlerseite",
                    name: "Error404Page",
                    params: { error: "404" }
                  });
                break;
            }  
            case 409: {
                // Duplicated object
                // Display an alert
                console.error("Object already exist!")
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 409: Duplicated object`,
                    timeout: 3000
                })
                break;
            }             
            case 500: {
                // Handle 500 here
                // redirect
                console.error("500 Internal Server Error!")
                this.store.dispatch('userFeedback/showUserFeedback', {
                    color: "error",
                    summary: 'Fehler',
                    message: `HTTP Request 500: Internal Server Error`,
                    timeout: 3000
                })
                break;
            }

            case 503: {
                // Handle 503 here
                // redirect             
                router.push({
                    path: "Fehlerseite",
                    name: "Error503Page",
                    params: { error: "503" }
                  });
                break;
            }
        }
    }
}

